<template>
  <div class="item-page">
    <div class="item-page__header" v-if="loading">
      <v-skeleton-loader
          type="list-item-avatar-three-line"
          width="50%"
          height="100%"
      />
    </div>
    <div class="item-page__body" v-if="loading">
      <v-skeleton-loader
          type="list-item, list-item, list-item, list-item"
          width="50%"
          height="100%"
      />
      <v-skeleton-loader
          type="list-item-avatar, list-item-avatar"
          width="50%"
          height="100%"
      />
    </div>
    <div class="item-page__header" v-if="!loading">
      <img :src="require('@/assets/img/pages/mailer.png')" alt="" class="mr-9">
      <div class="item-page__name">
        <v-text-field
            class="evi-text-field"
            color="#44D370"
            v-model="mailer.name"
            disabled
        >
          <template v-slot:label>
            Название рассылки
          </template>
        </v-text-field>
        <div class="item-page__actions mt-1">
          <v-btn text class="evi-button-text" @click="removeModal = true" v-if="hasRight('mailers.delete')">
            <img class="mr-2" :src="require('@/assets/img/users/red.svg')" alt="">
            Удалить
          </v-btn>
        </div>
      </div>
      <div class="item-page__close" @click="$router.go(-1)">
        <v-icon size="20">mdi-close</v-icon>
      </div>
    </div>
    <div class="item-page__body" v-if="!loading">
      <div class="item-page__edit">
        <v-text-field
            class="evi-text-field mb-7"
            color="#44D370"
            v-model="mailerLocal.name"
            placeholder="Название рассылки"
            @change="save"
            :disabled="!hasRight('mailers.manage')"
            hide-details
        >
          <template v-slot:label>
            Название рассылки
            <span class="important">*</span>
          </template>
        </v-text-field>
        <v-radio-group
            v-model="mailerLocal.is_regular"
            class="evi-radio-button-group mb-7"
            disabled
            @change="save"
            hide-details
        >
          <template v-slot:label>
            Тип рассылки
            <span class="important">*</span>
          </template>
          <div class="d-flex align-center">
            <v-radio
                v-for="item in types"
                :label="item.name"
                :value="item.value"
                :key="item.value"
                class="mb-0 mr-6 evi-radio"
                color="#45BF6A"
            />
          </div>
        </v-radio-group>
        <v-autocomplete
            class="evi-autocomplete mb-7"
            color="#44D370"
            :items="daysOfWeek"
            v-model="mailerLocal.days_of_week"
            item-text="name"
            item-value="id"
            clearable
            item-color="green"
            multiple
            v-if="mailerLocal.is_regular"
            :disabled="!hasRight('mailers.manage')"
            @change="save"
            hide-details
        >
          <template v-slot:label>
            Дни рассылки
          </template>
          <template v-slot:append>
                <span class="evi-autocomplete__arrow">
                    <v-icon>mdi-chevron-down</v-icon>
                </span>
          </template>
        </v-autocomplete>
        <v-menu
            v-model="dateMenu"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="auto"
            v-if="!mailerLocal.is_regular"
            :disabled="!hasRight('mailers.manage')"
            @change="save"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
                v-model="dateFormated"
                v-bind="attrs"
                v-on="on"
                readonly
                label="Дата"
                color="#44D370"
                class="evi-text-field mb-7"
                @change="save"
                hide-details
            >
              <template v-slot:label>
                Дата
                <span class="important">*</span>
              </template>
            </v-text-field>
          </template>
          <v-date-picker
              v-model="mailerLocal.send_date"
              @input="dateMenu = false"
              color="#44D370"
              first-day-of-week="1"
              @change="save"
          ></v-date-picker>
        </v-menu>
        <v-menu
            v-model="timeMenu"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="auto"
            :disabled="!hasRight('mailers.manage')"
            @change="save"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
                v-model="mailerLocal.send_time"
                v-bind="attrs"
                v-on="on"
                readonly
                label="Время"
                color="#44D370"
                class="evi-text-field mb-7"
                @change="save"
                hide-details
            >
              <template v-slot:label>
                Время
                <span class="important">*</span>
              </template>
            </v-text-field>
          </template>
          <v-time-picker
              v-model="mailerLocal.send_time"
              @input="timeMenu = false"
              color="#44D370"
              format="24hr"
              use-seconds
              @change="save"
          ></v-time-picker>
        </v-menu>

        <v-autocomplete
            class="evi-autocomplete mb-7"
            color="#44D370"
            v-model="mailerLocal.integration"
            :items="localIntegrations"
            item-text="name"
            item-value="id"
            hide-details
            :loading="integrationLoading"
            @focus="loadIntegrations()"
            :search-input.sync="integrationSearch"
            @input.native="searchIntegration(integrationSearch)"
            clearable
            item-color="green"
            :disabled="!hasRight('mailers.manage')"
            return-object
            @change="save"
        >
          <template v-slot:label>
            Рассылать по интеграции
          </template>
          <template v-slot:append-item>
            <div v-intersect="integrationIntersect"/>
          </template>
          <template v-slot:append>
                      <span class="evi-select__arrow">
                          <v-icon>mdi-chevron-down</v-icon>
                      </span>
          </template>
          <template v-slot:selection="{item}">
            <div class="v-select__selection v-select__selection--comma">
              <img width="20" class="mr-1"
                   :src="require(`@/assets/img/integrations/${item.type}.svg`)" alt=""/>
              {{ item.name }}
            </div>
          </template>
          <template v-slot:item="{item}">
            <v-list-item-title>
              <img width="20" class="mr-1"
                   :src="require(`@/assets/img/integrations/${item.type}.svg`)" alt=""/>
              {{ item.name }}
            </v-list-item-title>
          </template>
        </v-autocomplete>
        <v-autocomplete
            class="evi-autocomplete mb-7"
            color="#44D370"
            :items="statuses"
            v-model="mailerLocal.client_statuses"
            item-text="name"
            item-value="id"
            clearable
            item-color="green"
            multiple
            @focus="getStatuses()"
            :disabled="!hasRight('mailers.manage')"
            @change="save"
            hide-details
        >
          <template v-slot:label>
            Рассылать по статусам клиента
          </template>
          <template v-slot:append>
                  <span class="evi-autocomplete__arrow">
                      <v-icon>mdi-chevron-down</v-icon>
                  </span>
          </template>
          <template v-slot:selection="{item}">
            <div class="modal-create__color-select">
              <span class="color" :style="{backgroundColor: `${item.color}`}"/>
              {{ item.name }}
            </div>
          </template>
          <template v-slot:item="{item}">
            <div class="modal-create__color-select">
              <span class="color mt-1" :style="{backgroundColor: `${item.color}`}"/>
              {{ item.name }}
            </div>
          </template>
        </v-autocomplete>
        <v-autocomplete
            class="evi-autocomplete mb-7"
            color="#44D370"
            :items="tags"
            v-model="mailerLocal.tags"
            item-text="name"
            item-value="id"
            clearable
            item-color="green"
            multiple
            :disabled="!hasRight('mailers.manage')"
            @change="save"
            hide-details
        >
          <template v-slot:label>
            Рассылать по тегам
          </template>
          <template v-slot:append>
            <span class="evi-autocomplete__arrow">
                <v-icon>mdi-chevron-down</v-icon>
            </span>
          </template>
          <template v-slot:selection="{item}">
            <div class="evi-autocomplete__tag mr-2 mt-2"
                 :style="{backgroundColor: `${item.color}`, color: `${item.text_color}` }">
              {{ item.name }}
            </div>
          </template>
          <template v-slot:item="{item}">
            <div class="evi-autocomplete__tag"
                 :style="{backgroundColor: `${item.color}`, color: `${item.text_color}` }">
              {{ item.name }}
            </div>
          </template>
        </v-autocomplete>
        <v-autocomplete
            class="evi-autocomplete mb-7"
            color="#44D370"
            :items="tags"
            v-model="mailerLocal.tags_ignore"
            item-text="name"
            item-value="id"
            clearable
            item-color="green"
            multiple
            :disabled="!hasRight('mailers.manage')"
            @change="save"
            hide-details
        >
          <template v-slot:label>
            Не рассылать, если есть теги
          </template>
          <template v-slot:append>
            <span class="evi-autocomplete__arrow">
                <v-icon>mdi-chevron-down</v-icon>
            </span>
          </template>
          <template v-slot:selection="{item}">
            <div class="evi-autocomplete__tag mr-2 mt-2"
                 :style="{backgroundColor: `${item.color}`, color: `${item.text_color}` }">
              {{ item.name }}
            </div>
          </template>
          <template v-slot:item="{item}">
            <div class="evi-autocomplete__tag"
                 :style="{backgroundColor: `${item.color}`, color: `${item.text_color}` }">
              {{ item.name }}
            </div>
          </template>
        </v-autocomplete>
        <v-textarea
            no-resize
            class="evi-textarea mb-7"
            color="#44D370"
            v-model="mailerLocal.message"
            ref="message"
            :disabled="!hasRight('mailers.manage')"
            @change="save"
            hide-details
        >
          <template v-slot:label>
            Сообщение
          </template>
          <template v-slot:append>
            <EmojiPicker @pushEmoji="pushEmoji"/>
          </template>
        </v-textarea>
        <v-file-input
            class="evi-text-field"
            color="#44D370"
            v-model="files"
            label="Прикрепить файлы"
            accept="image/*, .doc, .docx, .pdf, .htm, .html, .txt, .rtf, .xml, .xlsx,.xls"
            multiple
            chips
            :disabled="!hasRight('mailers.manage')"
            @change="save"
        />
        <div class="attachments__items mb-7">
          <div v-for="item in mailer.attachments" :key="item.id" class="attachments__item">
            <v-icon @click="downloadFile(item.url, item.file_name)" size="20" class="mr-2">mdi-download</v-icon>
            <span @click="downloadFile(item.url, item.file_name)" :title="item.file_name">{{ item.file_name }}</span>
            <v-icon v-if="hasRight('mailers.manage')" @click="removeFile(item.id)" class="close" size="20">mdi-close</v-icon>
          </div>
        </div>
        <div class="evi-toolbar__night mb-7" :class="{'active': mailerLocal.notify}">
          <v-switch
              label="Уведомление о выполнении"
              v-model="mailerLocal.notify"
              :disabled="!hasRight('mailers.manage')"
              @change="save"
          ></v-switch>
        </div>
        <v-autocomplete
            v-if="mailerLocal.notify"
            class="evi-autocomplete mb-7"
            color="#44D370"
            :items="localUsers"
            v-model="mailerLocal.users"
            item-text="name"
            item-value="id"
            hide-details
            :loading="userLoading"
            @focus="loadUsers"
            :search-input.sync="userSearch"
            @input.native="searchUser(userSearch)"
            clearable
            item-color="green"
            multiple
            :disabled="!hasRight('mailers.manage')"
            @change="save"
        >
          <template v-slot:label>
            Кому отправлять уведомление
          </template>
          <template v-slot:append-item>
            <div v-intersect="userIntersect"/>
          </template>
          <template v-slot:item="{item}">
            <v-avatar
                color="#FFFFFF"
                size="30"
                class="user-page__avatar mr-3"
            >
              <img
                  :src="item.avatar_url ||require('@/assets/img/users/profile-avatar.png')"
                  alt=""
              >
            </v-avatar>
            <div class="v-list-item__title">{{ item.name }}</div>
          </template>
          <template v-slot:append>
                <span class="evi-autocomplete__arrow">
                    <v-icon>mdi-chevron-down</v-icon>
                </span>
          </template>
        </v-autocomplete>
      </div>
    </div>
    <v-dialog
        v-model="removeModal"
        max-width="600"
    >
      <v-card>
        <v-card-title class="text-h5">
          Удаление рассылки: "{{ mailerLocal.name }}"
        </v-card-title>
        <v-card-text>Вы действительно хотите удалить рассылку?</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
              text
              @click="removeModal = false"
          >
            Отмена
          </v-btn>
          <v-btn
              color="red"
              text
              @click="remove"
          >
            Удалить
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import {UserAutocompleteRequestsMixin} from "@/mixins/autocomplete/UserAutocompleteRequestsMixin";
import {hasRight} from '@/utils/access/hasRight';
import {mapActions, mapState} from "vuex";
import {IntegrationAutocompleteRequestsMixin} from "@/mixins/autocomplete/IntegrationAutocompleteRequestsMixin";
import {Weekdays} from "@/mixins/Weekdays";
import EmojiPicker from "@/components/EmojiPicker";
import {docsTypes} from "@/utils/attachmentsTypes";

export default {
  name: "MailerPage",
  components: {EmojiPicker},
  mixins: [IntegrationAutocompleteRequestsMixin, UserAutocompleteRequestsMixin, Weekdays],
  data: () => ({
    removeModal: false,
    dateMenu: false,
    timeMenu: false,
    files: [],
    mailerLocal: {
      name: '',
      is_regular: false,
      integration_id: null,
      send_date: null,
      send_time: '',
      message: '',
      days_of_week: [],
      tags: [],
      tags_ignore: [],
      client_statuses: [],
      users: [],
      notify: false,
      id: null,
    },
    types: [
      {
        name: 'Отложенная',
        value: false,
      },
      {
        name: 'Регулярная',
        value: true
      },
    ],
  }),
  computed: {
    dateFormated() {
      return this.mailer.send_date ? this.$moment(this.mailerLocal.send_date).format('DD.MM.YYYY') : null;
    },
    ...mapState("tags", ["tags",]),
    ...mapState("users", ["users",]),
    ...mapState("mailers", ['mailer',]),
    ...mapState("common", ["loading"]),
    ...mapState("integrations", ["integrations"]),
    ...mapState("client-statuses", ["statuses"]),
    localUsers() {
      let array = [...this.mailer.users];
      this.users.forEach(i => {
        if (!this.mailer.users.some(e => e.id === i.id)) {
          array.push(i);
        }
      });
      return array;
    },
    localStatuses() {
      let array = [...this.mailer.client_statuses];
      this.statuses.forEach(i => {
        if (!this.mailer.client_statuses.some(e => e.id === i.id)) {
          array.push(i);
        }
      });
      return array;
    },
    localIntegrations() {
      if (this.mailer.integration) {
        let array = [this.mailer.integration];
        this.integrations.forEach(i => {
          if (this.mailer.integration.id !== i.id) {
            array.push(i);
          }
        });
        return array.filter(i => i.type !== 'form');
      }
      return this.integrations.filter(i => i.type !== 'form');
    },
  },
  methods: {
    ...mapActions({
      getItem: 'mailers/getMailer',
      deleteItem: 'mailers/deleteMailer',
      editItem: 'mailers/editMailer',
      getTags: 'tags/getItemsBy',
      addFile: 'mailers/addFile',
      deleteFile: 'mailers/deleteFile',
      getStatuses: 'client-statuses/getStatusesBy',
    }),
    hasRight: hasRight,
    getData() {
      let id = this.$route.params.id;
      this.getItem(id);
    },
    save() {
      if (this.files.length) {
        //Если с файлами
        this.files.forEach((item) => {
          let formData = new FormData();

          if (docsTypes.some(type => type === item.type)) {
            formData.append('type', 'doc');
            formData.append('doc', item);
          } else {
            formData.append('type', 'image');
            formData.append('image', item);
          }

          this.addFile({id: this.mailer.id, formData: formData});
        });
      } else {
        let payload = {
          name: this.mailerLocal.name || this.mailer.name,
          id: this.mailer.id,
          message: this.mailerLocal.message,
          send_time: this.mailerLocal.send_time,
          is_regular: this.mailerLocal.is_regular,
          notify: this.mailerLocal.notify,
          integration_id: this.mailerLocal.integration.id,
          days_of_week: this.mailerLocal.days_of_week,
          users: this.mailerLocal.users,
          tags: this.mailerLocal.tags,
          tags_ignore: this.mailerLocal.tags_ignore,
          client_statuses: this.mailerLocal.client_statuses,
        }

        if (!this.mailerLocal.is_regular) {
          payload.send_date = this.mailerLocal.send_date;
        }

        this.editItem(payload);
      }
    },
    remove() {
      this.deleteItem(this.mailer).then(() => {
        this.removeModal = false;
        this.$router.push({path: '/mailers'});
      });
    },
    pushEmoji(e) {
      this.mailer.message += (e);
      this.$refs.message.focus();
    },
    removeFile(item) {
      this.deleteFile({id: this.mailer.id, fileId: item})
          .then(() => {
            let id = this.$route.params.id;
            this.getItem(id);
          });
    },
    downloadFile(fileUrl, fileName) {
      let a = document.createElement("a");
      a.href = fileUrl;
      a.setAttribute("download", fileName);
      a.setAttribute("target", '_blank');
      a.click();
    },
  },
  mounted() {
    this.getTags();
  },
  watch: {
    '$route.params.id': {
      handler: function () {
        this.getData();
      },
      deep: true,
      immediate: true
    },
    mailer(val) {
      this.mailerLocal.id = val.id;
      this.mailerLocal.name = val.name;
      this.mailerLocal.is_regular = val.is_regular;
      this.mailerLocal.integration = val.integration;
      this.mailerLocal.send_date = val.send_date;
      this.mailerLocal.send_time = val.send_time;
      this.mailerLocal.message = val.message;
      this.mailerLocal.days_of_week = val.days_of_week.map(i => i.toString());
      this.mailerLocal.tags = val.tags.map(i => i.id);
      this.mailerLocal.tags_ignore = val.tags_ignore.map(i => i.id);
      this.mailerLocal.users = val.users.map(i => i.id);
      this.mailerLocal.notify = val.notify;
      this.mailerLocal.client_statuses = val.client_statuses;
      this.files = [];
    }
  }
}
</script>

<style lang="scss" scoped>
.evi-autocomplete {
  max-width: 361px;
}
</style>
